import { useState } from "react";
import { Link } from "react-router-dom";
import "./Index.css";

export const RollUpService = () => {
  const [isHovered, setIsHovered] = useState(false);
  // Function to toggle hover state
  const handleHover = (value) => {
    setIsHovered(value);
  };
  return (
    <section id="RAAS">
      <div id="rt_welcome">
        {/* <div className="container"> */}
        <div className="section_heading_about text-center">
          <img src="" alt="" />
          <h3 className="heading_text text-center">Rollup As A Service</h3>
          <p className="heading_txt text-center">
            RaaS provides a convenient and accessible way for developers to
            deploy their own custom rollups without managing the underlying
            infrastructure.offers
          </p>
        </div>
        <div className="horizontal-card-grid">
          <div className="rt_service_box raas-card">
            <div>
              <h4 className="text-center">Rollup Stack</h4>
              <p className="align-center-item">
                <img
                  src="/icons/DorasLogo.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Doras
              </p>
              <p className="align-center-item">
                <img src="/icons/OP.svg" alt="" className="icon-briefcase-2" />
                Optimistic Rollups
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/Polygon.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Polygon
              </p>
              {/* Polygon */}
              <p className="align-center-item">
                <img
                  src="/icons/OptimisticRollups.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                <span>Optimistic Rollups</span>
              </p>
            </div>
          </div>
          <div className="rt_service_box raas-card">
            <div>
              <h4 className="text-center">Data Availability</h4>
              <p className="align-center-item">
                <img
                  src="/icons/ethereum.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Ethereum
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/celestia.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Celestia Light Node
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/availlight.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Avail Light Client
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/near.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                <span>Near</span>
              </p>
            </div>
          </div>
          <div className="rt_service_box raas-card">
            <div>
              <h4 className="text-center">Sequencer</h4>
              <p className="align-center-item">
                <img
                  src="/icons/DorasLogo.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Doras
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/espresso.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Espresso
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/radius.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Radius
              </p>
            </div>
          </div>
          <div className="rt_service_box raas-card">
            <div>
              <h4 className="text-center">Interoperability</h4>
              <p className="align-center-item">
                <img
                  src="/icons/DorasLogo.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Ethereum
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/celestia.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Celestia Light Node
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/availlight.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Avail Light Client
              </p>
            </div>
          </div>
          <div className="raas-card">
            <div className="rt_service_box ">
              <h4>Settlement</h4>
              <p className="align-center-item">
                <img
                  src="/icons/ethereum.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Ethereum
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/OptimisticRollups.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                <span>Optimistic Rollups</span>
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/Polygon.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                Polygon
              </p>
              <p className="align-center-item">
                <img
                  src="/icons/OptimisticRollups.svg"
                  alt=""
                  className="icon-briefcase-2"
                />
                <span>Optimistic Rollups</span>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="about_services">
              <div className="horizontal-card-grid">
                <div
                  onMouseEnter={() => handleHover(0)}
                  onMouseLeave={() => handleHover(false)}
                  className="rass-bt-box"
                >
                  <div className="rt_service_box">
                    {/* <img
                    src="/icons/pryzm.svg"
                    alt=""
                    className="icon-briefcase"
                  /> */}
                    <h4 className="align-center-item">
                      {" "}
                      <img
                        src={
                          isHovered === 0
                            ? "/icons/RI-4.svg"
                            : "/icons/RIB-4.svg"
                        }
                        alt=""
                        className="RI_icon"
                      />
                      <span>Gas Model</span>
                    </h4>
                    <ul className="ul-list">
                      <li>Priority Gas (1559)</li>
                      <li>Gas L1 Native / ERC20</li>
                      <li>Zero gas / Gas Fee</li>
                      <li>FIFO</li>
                    </ul>
                  </div>
                </div>
                <div
                  onMouseEnter={() => handleHover(1)}
                  onMouseLeave={() => handleHover(false)}
                  className="rass-bt-box"
                >
                  <div className="rt_service_box">
                    <h4 className="align-center-item">
                      {" "}
                      <img
                        src={
                          isHovered === 1
                            ? "/icons/RI-1.svg"
                            : "/icons/RIB-1.svg"
                        }
                        alt=""
                        className="RI_icon"
                      />
                      <span>Proof System</span>
                    </h4>
                    <ul className="ul-list">
                      <li>One-Step Proof</li>
                      <li>Bisection Fraud Proof</li>
                      <li>Fraud + ZK Proof</li>
                      <li>ZK Proof</li>
                    </ul>
                  </div>
                </div>
                <div
                  onMouseEnter={() => handleHover(2)}
                  onMouseLeave={() => handleHover(false)}
                  className="rass-bt-box"
                >
                  <div className="rt_service_box">
                    {/* <img
                    src="/icons/calestia.svg"
                    alt=""
                    className="icon-briefcase"
                  /> */}
                    <h4 className="align-center-item">
                      {" "}
                      <img
                        src={
                          isHovered === 2
                            ? "/icons/RI-2.svg"
                            : "/icons/RIB-2.svg"
                        }
                        alt=""
                        className="RI_icon"
                      />
                      <span>Rollup Type</span>
                    </h4>
                    <ul className="ul-list">
                      <li>Persistent</li>
                      <li>On - Demand</li>
                      <li>Flash Layer</li>
                      <li>Ephemeral</li>
                    </ul>
                  </div>
                </div>
                <div
                  onMouseEnter={() => handleHover(3)}
                  onMouseLeave={() => handleHover(false)}
                  className="rass-bt-box"
                >
                  <div className="rt_service_box">
                    <h4 className="align-center-item">
                      {" "}
                      <img
                        src={
                          isHovered === 3
                            ? "/icons/RI-5.svg"
                            : "/icons/RIB-5.svg"
                        }
                        alt=""
                        className="RI_icon"
                      />
                      <span>Security Guarantee</span>
                    </h4>
                    <ul className="ul-list">
                      <li>Tier 1: Multi Sequencer</li>
                      <li>Tier 2: Beacon Layer</li>
                      <li>Tier 3: L1 Settlement</li>
                      <li>Forkless Upgrade</li>
                    </ul>
                  </div>
                </div>
                <div
                  onMouseEnter={() => handleHover(4)}
                  onMouseLeave={() => handleHover(false)}
                  className="rass-bt-box"
                >
                  <div className="rt_service_box">
                    {/* <img
                    src="/icons/elixir.svg"
                    alt=""
                    className="icon-briefcase"
                  /> */}
                    <h4 className="align-center-item">
                      {" "}
                      <img
                        src={
                          isHovered === 4
                            ? "/icons/RI-3.svg"
                            : "/icons/RIB-3.svg"
                        }
                        alt=""
                        className="RI_icon"
                      />
                      <span>Staking / Slashing</span>
                    </h4>
                    <ul className="ul-list">
                      <li>Sequencer</li>
                      <li>Verifier</li>
                      <li>L2 Token</li>
                      <li>Restaking</li>
                    </ul>
                  </div>
                </div>
                {/* <div>
                <div className="rt_service_box">
                  <img
                    src="/icons/bevm.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>BEVM Validator Node</h4>
                  <p>Blockchain enables seamless tracking</p>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <Link className="gradient_btn" to="/service">
            <span>Book An Appointment</span>{" "}
          </Link>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

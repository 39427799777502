import "../../index.css";
import Header from "../Header copy/NewHeader";
import { Footer } from "../footer/footer";
export const Layout = ({ children }) => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <section>
        <div
          id="rt_banner"
          className="main_mobile_banner"
          style={{
            backgroundImage:
              "url(/images/bg-b4fbe3e9128c4600165896b97eecc4dc.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            transform: "translateY(0px)",
            height: "100%"
          }}
        >
          {children}
        </div>
      </section>
    </>
  );
};

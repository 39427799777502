// Header.js
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "../Header copy/header.css";
import { useNavigate, Link } from "react-router-dom";

function Header2() {
  const navigate = useNavigate();
  return (
    <header className="header">
      <div className="logo">
        <img src="images/logo.svg" alt="Logo" onClick={() => navigate("/")} />
      </div>
    </header>
  );
}

export default Header2;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./caraousel.css";
import "../../index.css";
import "./Service.css";
import { Link } from "react-router-dom";
export const Service = () => {
  return (
    <section id="Services">
      <div id="rt_features">
        <div className="shape_1"></div>
        {/* <div className="container"> */}
        <div className="row">
          <div className="mt_heading_white" style={{ width: "100%" }}>
            <h2 className="section_heading text-center">
              <span className="faded_text">Services</span>
              <span className="heading_text" style={{ color: "#000" }}>
                Services
              </span>
            </h2>
          </div>
          <div>
            <div className="Service-Left-Box col-sm-12 col-md-6 col-lg-6">
              <div className="Service-Left-Col-Box">
                <div>
                  <h3 className="text-white">NFT Marketplace Development:</h3>
                  <p className="text-white">
                    Empower your journey into the future of NFTs with our
                    blockchain-powered solutions for building innovative
                    marketplaces. Our dedicated team specializes in crafting
                    bespoke NFT marketplaces tailored to diverse niches.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: "url(/images/SL-1.webp)" }}
                ></div>
              </div>
              <div className="Service-Left-Col-Box">
                <div>
                  <h3 className="text-white">
                    Centralized Exchange Development
                  </h3>
                  <p className="text-white">
                    Crypto centralized exchange development involves creating
                    digital platforms where users can buy, sell, and trade
                    cryptocurrencies using centralized servers and
                    infrastructure managed by a single authority or entity.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: "url(/images/SL-2.webp)" }}
                ></div>
              </div>
              <div className="Service-Left-Col-Box">
                <div>
                  <h3 className="text-white">NEO Bank Development</h3>
                  <p className="text-white">
                    Neo bank development involves creating digital banking
                    platforms that operate entirely online, offering a range of
                    financial services such as savings accounts, payments,
                    loans, and investments without the need for physical
                    branches.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: "url(/images/SL-3.webp)" }}
                ></div>
              </div>
              <div className="Service-Left-Col-Box">
                <div>
                  <h3 className="text-white">Launchpad Development</h3>
                  <p className="text-white">
                    Launchpad development involves creating a platform that
                    facilitates the launching and fundraising of new blockchain
                    projects and token offerings.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: "url(/images/SL-4.webp)" }}
                ></div>
              </div>
              <div className="Service-Left-Col-Box">
                <div>
                  <h3 className="text-white">Multichain Bridge Development</h3>
                  <p className="text-white">
                    Multichain bridge development involves creating a robust
                    infrastructure that enables the seamless transfer of digital
                    assets between multiple blockchain networks.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: "url(/images/SL-5.webp)" }}
                ></div>
              </div>
              <div className="Service-Left-Col-Box">
                <div>
                  <h3 className="text-white">DAO Development</h3>
                  <p className="text-white">
                    DAO (Decentralized Autonomous Organization) development
                    involves creating governance structures and smart contracts
                    that enable decentralized decision-making and management of
                    resources within a community or organization.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: "url(/images/SL-6.webp)" }}
                ></div>
              </div>
            </div>
            <div className="Service-Right-Box col-sm-12 col-md-6 col-lg-6">
              <div className="Service-Right-Col-Box">
                <div
                  style={{ backgroundImage: "url(/images/SR-1.webp)" }}
                ></div>
                <div>
                  <h3 className="text-white">DEFI Protocol Development</h3>
                  <p className="text-white">
                    We provide innovative DeFi solutions that align perfectly
                    with your ultimate objectives. Leverage our development
                    expertise to constructer salient DeFi applications that
                    streamline rapid, efficient, and ongoing code delivery.
                  </p>
                </div>
              </div>
              <div className="Service-Right-Col-Box">
                <div
                  style={{ backgroundImage: "url(/images/SR-2.webp)" }}
                ></div>
                <div>
                  <h3 className="text-white">
                    Decentralized Applications (DApps) Development:
                  </h3>
                  <p className="text-white">
                    Cryptocurrency decentralized development involves building
                    blockchain-based platforms that enable peer-to-peer
                    transactions and interactions without the need for
                    intermediaries or centralized control.
                  </p>
                </div>
              </div>
              <div className="Service-Right-Col-Box">
                <div
                  style={{ backgroundImage: "url(/images/SR-3.webp)" }}
                ></div>
                <div>
                  <h3 className="text-white">Crypto Wallet Development</h3>
                  <p className="text-white">
                    Developing a range of cryptocurrency wallets facilitating
                    seamless virtual currency transfers. Our expertise spans
                    across diverse wallet types including mobile, web, hardware,
                    and desktop wallets.
                  </p>
                </div>
              </div>
              <div className="Service-Right-Col-Box">
                <div
                  style={{ backgroundImage: "url(/images/SR-4.webp)" }}
                ></div>
                <div>
                  <h3 className="text-white">Blockchain Integration:</h3>
                  <p className="text-white">
                    Integrating blockchain solutions with existing systems and
                    infrastructure to enhance transparency, efficiency, and
                    security in various business processes.  
                  </p>
                </div>
              </div>
              <div className="Service-Right-Col-Box">
                <div
                  style={{ backgroundImage: "url(/images/SR-5.webp)" }}
                ></div>
                <div>
                  <h3 className="text-white">
                    Layer 1 & 2 Blockchain Solutions
                  </h3>
                  <p className="text-white">
                    Layer 1 blockchain development involves creating the
                    foundational layer of a blockchain network, which includes
                    designing and implementing the core protocol, consensus
                    mechanisms, and network infrastructure.
                  </p>
                </div>
              </div>
              <div className="Service-Right-Col-Box">
                <div
                  style={{ backgroundImage: "url(/images/SR-6.webp)" }}
                ></div>
                <div>
                  <h3 className="text-white">DAAP Development</h3>
                  <p className="text-white">
                    Developing decentralized applications that run on blockchain
                    networks, providing transparent, secure, and
                    tamper-resistant functionality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <Link className="gradient_btn" to="/service">
            <span>Book An Appointment</span>{" "}
          </Link>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export const BlockChainService = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="BAAS">
      <div id="rt_welcome" style={{ paddingBottom: "0px" }}>
        <div className="section_heading_about text-center slick-cont">
          <h3 className="section_heading text-center">
            Blockchain As A Service
          </h3>
          <div>
            <h2 className="section_heading text-left">Chain Configurations</h2>
            <p className="heading_txt text-left">
              Don’t be limited to one type of chain. With DORAS you can build
              the chain configuration that best suites your project.
            </p>
          </div>
        </div>

        <div className="row slick-cont">
          <div className="col-sm-12">
            <div className="about_services">
              <div className="rt_about_us">
                <Slider {...settings}>
                  <div>
                    <div className="rt_service_box">
                      <img
                        src="/icons/public-chains.svg"
                        alt=""
                        class="icon-briefcase"
                      />
                      <h4>Public Chains</h4>
                      <p>
                        Easily run enterprise - grade nodes for public chains.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="rt_service_box">
                      <img
                        src="/icons/private-chains.svg"
                        alt=""
                        class="icon-briefcase"
                      />
                      <h4>Private Chains</h4>
                      <p>Quickly launch high-performance private chains.</p>
                    </div>
                  </div>
                  <div>
                    <div className="rt_service_box">
                      <img
                        src="/icons/app-chains.svg"
                        alt=""
                        class="icon-briefcase"
                      />
                      <h4>Consortium Chains</h4>
                      <p>
                        Build and deploy complete permissioned multi-party
                        chains.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="rt_service_box">
                      <img
                        src="/icons/side-chains.svg"
                        alt=""
                        class="icon-briefcase"
                      />
                      <h4>Side Chains</h4>
                      <p>Create zero-gas chains with super-scale RPCs.</p>
                    </div>
                  </div>
                  <div>
                    <div className="rt_service_box">
                      <img
                        src="/icons/cons-chains.svg"
                        alt=""
                        class="icon-briefcase"
                      />
                      <h4>App Chains</h4>
                      <p>
                        Utility app chain ecosystem chains for your products &
                        services.
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "70px" }}
      >
        <Link className="gradient_btn" to="/service">
          <span>Book An Appointment</span>{" "}
        </Link>
      </div>
    </section>
  );
};

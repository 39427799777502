export const config = {
  authEndPoints: {
    register: "/v0/user/user-signup",
    login: "/v0/user/login",
    forgotPassword: "/v0/user/forgot-password",
    otpVerification: "/v0/user/verify-otp",
    sendOtp: "/v0/user/send-otp",
    blockChainService: "/v0/user/geth-add-folder",
    contactUs: "/v0/user/contact-us",
    rollUp: "/v0/services/add-roll-as-service",
    contact: "/v0/user/contact-us",
    dockerRunFile: "/v0/user/geth-container-run",
    subscribe: "/v0/user/subscribe-email",
    googleLogin: "/v0/user/google-signin",
    googleSignup: "/v0/user/google-signup",
    clonePolygon: "/v0/user/clone-polygon",
    copyPolygon: "/v0/user/copy-polygon",
    createFiles: "/v0/user/create-files",
    dockerFolderRun: "/v0/user/docker-make-build",
    dockerContainerRun: "/v0/user/polygon-docker-container-run",
    gethContainerStatus: "/v0/user/geth-container-status",
    gethRemoveContainer: "/v0/user/geth-remove-container",
    gethDownContainer: "/v0/user/geth-down-blockscout",
    gethDeleteFolder: "/v0/user/geth-delete-folder",
    getPorts: "/v0/user/get-ports"
  },
};

import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import Radio from '@mui/material/Radio/Radio';
import "./css/environment.css";

const data = [
    {
        label: "BaaS Environments",
        title: "Standard Blockchain Service",
        statement: "Deploy the blockchain nodes in your own secure multi-cloud hosting environment. Access dozens of pre-built tools and services to rapidly built and scale blockchain solutions.",
        learn: "Learn more"
    },
    {
        label: "Sandbox Environments",
        title: "FireFly Private Network Sandbox",
        statement: "Quickly and easily test drive FireFly along with Doras’s pre-built tools and services  ",
        learn: "Learn more"
    },
]



const NetworkDetails = ({setTab}) => {
    const[environment, setEnvironment] = useState(    {
        label: "BaaS Environments",
        title: "Standard Blockchain Service",
        statement: "Deploy the blockchain nodes in your own secure multi-cloud hosting environment. Access dozens of pre-built tools and services to rapidly built and scale blockchain solutions.",
        learn: "Learn more"
    });
    const handleSubmit = () =>{
      
        if(environment){
            setTab("Protocol")
        }
    }
    return (
        <div className="environments_main">
            <div>
                <h3>Select an Environment Type</h3>
                <p className="white_color">{"Choose the type of environment you would like to create."}</p>
            </div>
            <div className="environments_hr_line"></div>
            <div className="environments_main_form">
                <Grid container spacing={2}>
                    {data.map((item, i) => (
                        <Grid item xs={12} md={6} key={i}>
                            <div className="environments_label_form_box">
                                <label>{item.label}</label>
                                <div className={i == 0 ? "environments_card active_border" : "environments_card diable_card"} onClick={()=>{i == 0 && setEnvironment(item)}}>
                                    <div className="radio_div">
                                        <Radio name="region" checked={environment.title == item.title}/>
                                    </div>
                                    <div className="content_div">
                                    <p className="text_Align_left card_heading">{item.title}</p>
                                    <p className="text_Align_left">{item.statement}</p>
                                    <p className="text_Align_left learn_more_color">{item.learn}</p>
                                    </div>
                                  
                                </div>
                            </div>

                        </Grid>
                    ))}
                    <Grid item xs={12} sm={6} style={{ textAlign: "center", marginTop: 40 }}>
                        <button onClick={()=>setTab("DeployRegions")} className="setup_node_button border_none">
                            Cancel
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: "center", marginTop: 40 }}>
                        <button onClick={handleSubmit} type="submit" className="setup_node_button">
                            Next
                        </button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default NetworkDetails;

import React, { useState } from "react";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio/Radio";
import Header from "../../components/Header copy/NewHeader";
import Header2 from "../../components/header/header";
import "./css/configure.css";
const data = [
  {
    title: "Small",
    statement: "(1GB Memory, 0.5 vCPU)",
  },
  {
    title: "Medium",
    statement: "(2GB Memory, 1 vCPU)",
  },
  {
    title: "Large",
    statement: "(4GB Memory, 2 vCPU)",
  },
];

const NetworkDetails = ({ setTab }) => {
  const [configure, setConfigure] = useState({
    title: "Small",
    statement: "(1GB Memory, 0.5 vCPU)",
  });
  const handleSubmit = () => {
    if (configure) {
      setTab("create");
    }
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Header2 />
      <div className="configure_main">
        <div>
          <h3>Configure Your Node</h3>
          <p className="white_color">
            {
              "Configure the size of your node and whether or not it will be a signer."
            }
          </p>
        </div>

        <div className="configure_main_form">
          <div className="configure_label_form_box">
            <label>{"Node size"}</label>
            <Grid container spacing={2}>
              {data.map((item, i) => (
                <Grid item xs={12} md={4} key={i}>
                  <div
                    className={
                      i == 0
                        ? "configure_card active_border"
                        : "configure_card diable_card"
                    }
                    onClick={() => {
                      i == 0 && setConfigure(item);
                    }}
                  >
                    <div className="radio_div">
                      <Radio
                        name="region"
                        checked={configure.title == item.title}
                      />
                    </div>
                    <div className="configure_content_div">
                      <p className="text_Align_left card_heading">
                        {item.title}
                      </p>
                      <p className="text_Align_left">{item.statement}</p>
                    </div>
                  </div>
                </Grid>
              ))}

              <Grid
                item
                xs={12}
                sm={6}
                style={{ textAlign: "center", marginTop: 40 }}
              >
                <button
                  onClick={() => setTab("Provider")}
                  className="setup_node_button border_none"
                >
                  Back
                </button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ textAlign: "center", marginTop: 40 }}
              >
                <button onClick={handleSubmit} className="setup_node_button">
                  Finish
                </button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkDetails;

import { Link } from "react-router-dom";
const data = [
  {
    name: "ether",
    icon: "/icons/icon-1.svg",
  },
  {
    name: "avalanche",
    icon: "/icons/icon-2.svg",
  },
  {
    name: "binance",
    icon: "/icons/icon-3.svg",
  },
  {
    name: "solana",
    icon: "/icons/icon-4.svg",
  },
  {
    name: "1",
    icon: "/icons/icon-5.svg",
  },
  {
    name: "2",
    icon: "/icons/icon-6.svg",
  },
  {
    name: "3",
    icon: "/icons/icon-7.svg",
  },
  {
    name: "4",
    icon: "/icons/icon-8.svg",
  },
  {
    name: "5",
    icon: "/icons/icon-9.svg",
  },
  {
    name: "6",
    icon: "/icons/icon-10.svg",
  },
  {
    name: "7",
    icon: "/icons/icon-11.svg",
  },
  {
    name: "8",
    icon: "/icons/icon-12.svg",
  },
  {
    name: "9",
    icon: "/icons/icon-13.svg",
  },
  {
    name: "10",
    icon: "/icons/icon-14.svg",
  },
  {
    name: "11",
    icon: "/icons/icon-15.svg",
  },
  {
    name: "12",
    icon: "/icons/icon-16.svg",
  },
  {
    name: "13",
    icon: "/icons/icon-17.svg",
  },
  {
    name: "14",
    icon: "/icons/icon-18.svg",
  },
  {
    name: "15",
    icon: "/icons/icon-19.svg",
  },
  {
    name: "16",
    icon: "/icons/icon-20.svg",
  },
  {
    name: "16",
    icon: "/icons/icon-21.svg",
  },
  {
    name: "17",
    icon: "/icons/icon-22.svg",
  },
  {
    name: "18",
    icon: "/icons/icon-23.svg",
  },
  {
    name: "18",
    icon: "/icons/icon-24.svg",
  },
  {
    name: "18",
    icon: "/icons/icon-25.svg",
  },
  {
    name: "18",
    icon: "/icons/icon-26.svg",
  },
  {
    name: "18",
    icon: "/icons/icon-27.svg",
  },
  {
    name: "18",
    icon: "/icons/icon-28.svg",
  },
];
export const Protocols = () => {
  return (
    <div
      id="rt_welcome"
      style={{
        paddingTop: "0px",
      }}
    >
      <div className="container">
        <div className="section_heading_about text-center">
          <h3 className="section_heading text-center">Protocol</h3>
          <p className="heading_txt text-center">
            Driving Ingenuity With Relentless Blockchain Innovation
          </p>
        </div>
        <div className="row" style={{justifyContent: "center"}}>
          {data.map((data) => {
            return (
              <div className="col-md-2 col-sm-3 col-xs-4 proto-icons" >
                <img src={data.icon} alt={data.name}  className="protocol-img"/>
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "70px" }}
      >
        <Link className="gradient_btn" to="/service">
          <span>Book An Appointment</span>{" "}
        </Link>
      </div>
    </div>
  );
};

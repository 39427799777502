import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import "./css/setUpNode.css";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    membership: Yup.string().required("membership is required"),
    region: Yup.string().required("Region is required"),
});

const initialValues = {
    membership: "",
    name: "",
    region:""
};

const NetworkDetails = ({setTab}) => {
    const handleSubmit = (values) => {
        // Handle form submission here
        // console.log("Form submitted:", values);
        setTab("DeployRegions");
    };

    return (
        <div className="setup_node_main">
            <div>
            <h3>Set Up Your Node</h3>
            <p>{"Provision a node to take part in this environment "}</p>
            </div>
        
            <div className="setup_node_main_form">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <div className="setup_node_label_form_box">
                                    <label>{"Select membership"}</label>
                                    <Field as="select" name="membership">
                                        <option value="">Select Option</option>
                                        <option value="shdskja">Example organization</option>
                                        {/* <option value="BSC">BSC</option> */}
                                    </Field>
                                    <ErrorMessage name="membership" component="div" style={{ color: "red" }} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="setup_node_label_form_box">
                                    <label>Name of this node</label>
                                    <Field type="text" name="name" placeholder="Name of node" />
                                    <ErrorMessage name="name" component="div" style={{ color: "red" }} />
                                </div>
                            </Grid>


                            <Grid item xs={12} >
                                <div className="setup_node_label_form_box">
                                    <label>Select Region</label>
                                    <Field as="select" name="region">
                                    <option value="">Select Option</option>
                                        <option value="dfas">AWS:us-east-2</option>
                                        {/* <option value="Geth">Geth</option> */}
                                        {/* <option value="BSC">BSC</option> */}
                                    </Field>
                                    <ErrorMessage name="region" component="div" style={{ color: "red" }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ textAlign: "center", marginTop: 40 }}>
                                <button className="setup_node_button border_none">
                                    Cancel
                                </button>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ textAlign: "center", marginTop: 40 }}>
                                <button type="submit" className="setup_node_button">
                                    Next
                                </button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default NetworkDetails;

import "./loading.css";
export const Loading = () => {
  return (
    <div className="Loader-Container">
      <div class="loading1">
        <div class="loading__letter">L</div>
        <div class="loading__letter">o</div>
        <div class="loading__letter">a</div>
        <div class="loading__letter">d</div>
        <div class="loading__letter">i</div>
        <div class="loading__letter">n</div>
        <div class="loading__letter">g</div>
        <div class="loading__letter">.</div>
        <div class="loading__letter">.</div>
        <div class="loading__letter">.</div>
      </div>
    </div>
  );
};

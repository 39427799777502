import React, { useState } from "react";
import { Grid } from "@mui/material";
import Radio from '@mui/material/Radio/Radio';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import "./css/deployRegions.css";

const data = [
    {
        country: "Ohio",
        flag: "/flag/unitedKingdom.svg",
        server: "AWS",
        region: "us-east-2"
    },
    {
        country: "Sydney",
        flag: "/flag/sydney.svg",
        server: "AWS",
        region: "ap-southeast-2"
    },
    {
        country: "Washington",
        flag: "/flag/unitedStates.svg",
        server: "Azure",
        region: "westus2"
    }
]
const secondData = [
    {
        country: "Frankurt",
        flag: "/flag/frankurt.svg",
        server: "AWS",
        region: "eu-central-1"
    },
    {
        country: "Seoul",
        flag: "/flag/seoul.svg",
        server: "AWS",
        region: "ap-northeast-2"
    },
    {
        country: "Paris",
        flag: "/flag/paris.svg",
        server: "Azure",
        region: "Francecentral"
    },
]

const NetworkDetails = ({ setTab }) => {
    const [region, setRegion] = useState("");
    const handleSubmit = () => {
        if (region) {
            setTab("EnvironmentType")
        }
    }
    return (
        <div className="deploy_regions_main">
            <div>
                <h3>Enable Multi Region- Add Deployment Regions</h3>
                <p>{"BaaS allows blockchain networks to span geographic. Your members can choose from these regions when creating nodes."}</p>
            </div>
            <div className="deploy_regions_main_form">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                    {data.map((item, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{ border: 0 }}
                                            onClick={() => setRegion(item)}
                                        >
                                            <TableCell align="left" style={{ width: 70, border: "none" }}>
                                                <Radio name={item.country} checked={region.country === item.country} />
                                            </TableCell>
                                            <TableCell align="left" style={{ border: "none" }}> <p style={{ textAlign: "left" }}>{item.country}</p></TableCell>
                                            <TableCell align="left" style={{ border: "none" }}> <img src={item.flag} alt={item.country} style={{ width: 30 }} /></TableCell>
                                            <TableCell align="left" style={{ border: "none" }}> <p style={{ textAlign: "left" }}>{item.server}</p></TableCell>
                                            <TableCell align="left" style={{ border: "none" }}>  <p style={{ textAlign: "left" }}>{item.region}</p></TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                    {secondData.map((item, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{ border: 0 }}
                                            onClick={() => setRegion(item)}
                                        >
                                            <TableCell align="left" style={{ width: 70, border: "none" }}>
                                                <Radio name={item.country} checked={region.country === item.country} />
                                            </TableCell>
                                            <TableCell align="left" style={{ border: "none" }}> <p style={{ textAlign: "left" }}>{item.country}</p></TableCell>
                                            <TableCell align="left" style={{ border: "none" }}> <img src={item.flag} alt={item.country} style={{ width: 30 }} /></TableCell>
                                            <TableCell align="left" style={{ border: "none" }}> <p style={{ textAlign: "left" }}>{item.server}</p></TableCell>
                                            <TableCell align="left" style={{ border: "none" }}>  <p style={{ textAlign: "left" }}>{item.region}</p></TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: "center", marginTop: 40 }}>
                        <button className="setup_node_button border_none">
                            Cancel
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: "center", marginTop: 40 }}>
                        <button onClick={handleSubmit} className="setup_node_button">
                            Next
                        </button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default NetworkDetails;

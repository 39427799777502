import { Link } from "react-router-dom";
import "../../index.css";
export const Banner = () => {
  return (
    <section id="Home">
      <div
        id="rt_banner"
        className="main_mobile_banner"
        style={{
          backgroundImage:
            "url(/images/bg-b4fbe3e9128c4600165896b97eecc4dc.png)",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-6">
              <div className="banner_caption_text">
                <h1>Blockchain Service Provider</h1>
                <p>
                  Flagship solution designed to simplify blockchain
                  implementation for businesses regardless of their technical
                  expertise.{" "}
                </p>
                <Link className="gradient_btn" to="/register">
                  <span>GET STARTED</span>{" "}
                </Link>
              </div>
            </div>
            <div className="col-md-5 col-md-offset-1">
              <div className="banner_caption_img">
                <img
                  src="/images/hp-1.svg"
                  alt="Phone"
                  style={{ animation: "none" }}
                  className="fadeInUp"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

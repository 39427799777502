import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faTwitter,
  faMedium,
  faReddit,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import { faLocationDot, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { post } from "../../service/apiService";
import { config } from "../../config";
import { useState } from "react";
import toast from "react-hot-toast";
import "../../index.css";
import "../../App.css";
import "./footer.css";
const { subscribe } = config.authEndPoints;
export const Footer = () => {
  const [mail, setMail] = useState("");
  const handleSubscribe = async () => {
    if (mail.trim() !== "") {
      try {
        const response = await post(
          subscribe,
          { email: mail },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.success) {
          toast.success(response?.message);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {}
    }
  };
  return (
    <footer id="mt_footer">
      <div className="footer-contaner">
        <div className="row">
          <div className="col-md-4">
            <div className="follow_us">
              <Link className="logo" to="/">
                <img src="/images/logo.svg" alt="Logo" />
              </Link>
              <p>
                Simplifying Integration For Businesses of All Sizes
                <br />
                DORAS LAB - The architects of digital transformation
              </p>
              {/* <h4>Follow Us on</h4> */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mt_contact_ftr">
              <ul style={{ display: "flex", flexDirection: "column" }}>
                <Link to="/contact" style={{ color: "#000" }}>
                  <h2 style={{ marginBottom: "10px" }}>Contact us</h2>
                </Link>
                <li>
                  <Link
                    to="https://maps.app.goo.gl/DzbjKfrTYCaq92E37"
                    target="_blank"
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      color: "#000",
                      marginBottom: "0px",
                    }}
                  >
                    <FontAwesomeIcon icon={faLocationDot} />
                    80 ROBINSON ROAD, SINGAPORE
                  </Link>
                </li>
                <li>
                  <a
                    href="mailto:info@doraslab.io"
                    style={{
                      color: "#000",
                      marginBottom: "0px",
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                    info@doraslab.io
                  </a>
                </li>
              </ul>
              <h2 style={{ marginBottom: "15px" }}>Join our community</h2>
              <ul
                className="social_icons"
                style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
              >
                <li>
                  <Link to="https://twitter.com/Doras_lab" target="_blank">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/doras_lab"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/doraslab"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://discord.com/channels/1210531779262423110/1210531779774259237"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faDiscord} />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.reddit.com/user/doraslab">
                    <FontAwesomeIcon icon={faReddit} target="_blank" />
                  </Link>
                </li>
                <li>
                  <Link to="https://medium.com/@doras_lab" target="_blank">
                    <FontAwesomeIcon icon={faMedium} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mt_post_ftr">
              <h2>Subscriber our Newletters</h2>
              <div className="newsletter_form">
                <input
                  type="email"
                  placeholder="Enter your email id"
                  className="form-control"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
                <button className="animated_btn" onClick={handleSubscribe}>
                  subscribe
                  <span>
                    <i className="ion-android-arrow-forward"></i>
                  </span>
                </button>
                {/* <p>
                  <small>* we won´t spam you, never!</small>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt_footer_copy">
        <div className="footer-contaner">
          <div className="row">
            <div className="col-sm-6">
              <div className="copy_txt">
                <p style={{ color: "#000" }}>
                  © Copyright 2024 - Doras All rights Reserved
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="made_txt">
                <p>
                  <Link
                    style={{ color: "#000" }}
                    to={"https://bitviraj.com/"}
                    target="_blank"
                  >
                    Powered by @ Bitviraj <i className="fa fa-heart"></i>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

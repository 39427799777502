import { Link } from "react-router-dom";
import "../../index.css";
import "./Index.css";
export const NodeService = () => {
  return (
    <section id="NAAS">
      <div
        id="rt_welcome"
        style={{
          backgroundImage: "url(/icons/nodeServiceBg.svg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* <div className="container"> */}
        <div className="section_heading_about text-center">
          <img src="" alt="" />
          <h3 className="heading_text text-center">Node As A Service</h3>
          <p className="heading_txt text-center">
            Node as a Service (NaaS) offers
          </p>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="about_services">
              <div className="rt_about_us">
                <div className="col-md-4 col-sm-12">
                  <div className="rt_service_box naas">
                    <img
                      src="/icons/pryzm.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h4>Pryzm Validator Testnet</h4>
                    <p>
                      Prysm is an Ethereum 2.0 client that allows users to run a
                      validator node on the Ethereum 2.0 testnet.
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="rt_service_box naas">
                    <img
                      src="/icons/babylon.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h4>Babylon Validator Testnet</h4>
                    <p>
                      The Babylon validator testnet allows individuals to
                      participate in the Babylon blockchain ecosystem by
                      becoming validators on the testnet.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="rt_service_box naas">
                    <img
                      src="/icons/calestia.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h4>Celestia Light Node</h4>
                    <p>
                      A Celestia light node is designed to allow users to
                      interact with the celestia blockchain network without
                      requiring the resources needed for running a full node.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="rt_service_box naas">
                    <img
                      src="/icons/elixir.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h4>Elixir Validator Node</h4>
                    <p>
                      An Elixir validator node is a crucial component of the
                      Elixir blockchain network responsible for executing the
                      underlying algorithm and achieving consensus within the
                      network.
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="rt_service_box naas">
                    <img
                      src="/icons/avail.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h4>Avail Light Client</h4>
                    <p>
                      The Avail Light Client provides a decentralized and secure
                      way to interact with the Avail blockchain without needing
                      traditional full nodes, ensuring data availability on the
                      network.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="rt_service_box naas">
                    <img
                      src="/icons/bevm.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h4>BEVM Validator Node</h4>
                    <p>
                      The BEVM validator node is responsible for participating
                      in consensus and validating transactions on the network.
                    </p>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="horizontal-card-grid" style={{ padding: "0 15px" }}>
          <div>
            <div className="rt_service_box naas-bt">
              <i className="icon-presentation"></i>
              <h4>Enterprise-Grade Security</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>
          <div>
            <div className="rt_service_box naas-bt">
              <i className="icon-strategy"></i>
              <h4>Deployment Options</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>
          <div>
            <div className="rt_service_box naas-bt">
              <i className="icon-wallet"></i>
              <h4>High Performance</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>
          <div>
            <div className="rt_service_box naas-bt">
              <i className="icon-presentation"></i>
              <h4>Real-Time Data Analytics</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>
          <div>
            <div className="rt_service_box naas-bt">
              <i className="icon-presentation"></i>
              <h4>24/7 Resource Monitoring</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>
        </div> */}
        {/* </div> */}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "70px" }}
      >
        <Link className="gradient_btn" to="/service">
          <span>Book An Appointment</span>{" "}
        </Link>
      </div>
      <div style={{ marginBottom: "50px" }}></div>
    </section>
  );
};

import { Link } from "react-router-dom";
import "../../index.css";
export const Preffered = () => {
  return (
    <div id="rt_welcome">
      <div className="container">
        <div className="section_heading_about text-center">
          <span>Why Choose Us</span>
          <h3>
            Doras as a Blockchain Service Provider addresses several critical
            needs within industries and enterprises
          </h3>
        </div>
        <div className="row">
          {/* col-sm-12 col-md-6 col-lg-3 */}
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="about_services">
              <div className="rt_about_us">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="rt_service_box">
                    <img
                      src="/icons/simple.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h5>Simplifying Implementation</h5>
                    <p>
                      Offering pre-built solutions, tools, and support services
                      tailored to the specific needs of different industries,
                      thereby making blockchain adoption convenient.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="rt_service_box">
                    <img
                      src="/icons/reduceCost.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h5>Reducing Costs</h5>
                    <p>
                      Ready-made solutions and support services to reduce the
                      costs associated with blockchain implementation, making it
                      more feasible for businesses of all sizes.
                    </p>
                    <br/>
                    <br/>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="rt_service_box">
                    <img
                      src="/icons/Efficiency.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h5>Improving Efficiency</h5>
                    <p>
                      Doras can help businesses integrate blockchain solutions
                      seamlessly into their existing workflows, leading to
                      improved efficiency, transparency, and traceability.
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="rt_service_box">
                    <img
                      src="/icons/innovation.svg"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h5>Fostering Innovation</h5>
                    <p>
                      Doras can empower businesses to innovate and explore new
                      use cases for the technology. This in turn can drive
                      greater competitiveness, differentiation, and value
                      creation within industries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 about_sec_img">
            <div className="mt_about_img">
              <img src="/images/hp-2.svg" alt="About Image" />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <Link className="gradient_btn" to="/service">
            <span>Book An Appointment</span>{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

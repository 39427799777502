import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { get, post, remove } from "../../service/apiService";
import { config } from "../../config";
import { useEffect, useState } from "react";
import Header from "../../components/Header copy/NewHeader";
import { Footer } from "../../components/footer/footer";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
// import { makeStyles } from "@mui/material";
// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
//   tableHeadCell: {
//     backgroundColor: "#f5f5f5",
//     fontWeight: "bold",
//   },
//   tableRow: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: "#e0e0e0",
//     },
//   },
// });
const StyledTableContainer = styled(TableContainer)({
  maxWidth: "100%", // Example styling
  margin: "0 auto", // Example styling
  minHeight: "600px",
});
const StyledTable = styled(Table)({
  minWidth: 400, // Example styling
});

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold", // Example styling
  fontSize: "18px",
  textAlign: "center",
});

const StyledTableCell2 = styled(TableCell)({
  fontWeight: "normal", // Example styling
  fontSize: "14px",
  textAlign: "center",
  textTransform: "capitalize",
});
const {
  gethContainerStatus,
  gethRemoveContainer,
  gethDownContainer,
  gethDeleteFolder,
} = config.authEndPoints;
export const DeleteContainer = () => {
  const [containerdata, setContainerData] = useState([]);


  const handleDelete = async () => {
    try {
      await get(gethRemoveContainer, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      await get(gethDownContainer, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      const response = await remove(gethDeleteFolder, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (response?.message) {
        toast.success("Container Delete Successfully");
      }
      console.log(response);
    } catch (err) {
      toast.error(err)
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(gethContainerStatus, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        if (response?.success) {
          setContainerData(response?.responseData);
        }
        // console.log(response, "Response");
      } catch (error) {}
    };
    fetchData();
  }, [handleDelete]);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div id="__next">
      <Header click={scrollToSection}/>
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableCell>Serial No.</StyledTableCell>
              <StyledTableCell>Chain Id</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Explorer URL</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {containerdata?.map((data, index) => (
              <TableRow key={index}>
                <StyledTableCell2>{index + 1}</StyledTableCell2>
                <StyledTableCell2>{data?.chainId}</StyledTableCell2>
                <StyledTableCell2>{data?.email}</StyledTableCell2>
                <StyledTableCell2
                  style={{
                    color: data?.status === "running" ? "green" : "oranges",
                  }}
                >
                  {data?.status}
                </StyledTableCell2>
                <StyledTableCell2 style={{ textTransform: "lowercase" }}>
                  <Link to={data?.explorer_url} target="_blank">
                    {data?.explorer_url}
                  </Link>
                </StyledTableCell2>
                <StyledTableCell2>
                  <button style={{ color: "red" }} onClick={handleDelete}>
                    Delete
                  </button>
                </StyledTableCell2>
              </TableRow>
            ))}
            {/* {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.column1}</TableCell>
              <TableCell>{row.column2}</TableCell>
              <TableCell>{row.column3}</TableCell>
            </TableRow>
          ))} */}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      <Footer />
    </div>
  );
};

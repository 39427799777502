import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./caraousel.css";
import "./Index.css";
export const Solution = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="Solutions">
      <div
        id="rt_welcome"
        style={{
          paddingTop: "0px",
        }}
      >
        {/* <div className="container"> */}
        <div className="section_heading_about text-center">
          <h3 className="section_heading text-center">Solutions</h3>
          <p className="heading_txt text-center">
            Doras caters to a wide range of use cases across various sectors
            using blockchain technology due to
            <br /> its decentralized, secure, and transparent nature. Here's how
            Dorasleverages these key characteristics to address specific
            industry challenges:
          </p>
        </div>
        <div className="slick-cont">
          <Slider {...settings}>
            <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img
                    src="/icons/banking-finance.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Finance and Banking</h4>
                  <p>
                    In the finance and banking sector, Doras provides solutions
                    for secure and transparent transactions, cross-border
                    payments, smart contracts, and identity management. By
                    leveraging blockchain's decentralized nature, Doras enhances
                    trust, reduces transaction costs, and mitigates the risk of
                    fraud.
                    <br />
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img
                    src="/icons/supply-chain.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Supply Chain Management</h4>
                  <p>
                    Doras offers blockchain-based solutions for supply chain
                    transparency, traceability, and provenance. By recording
                    transactions on an immutable ledger, Doras ensures
                    transparency and accountability throughout the supply chain,
                    helping organizations combat counterfeit goods and
                    streamline logistics processes.
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img
                    src="/icons/counterfit-management.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Counterfeit Management</h4>
                  <p>
                    By providing an immutable andtransparent ledger of
                    transactions. Creation to distribution toverify the
                    authenticity of goods at any point in the supply chain. This
                    ensurescounterfeit products are easily identified and
                    eliminated, reducing thefinancial losses and reputational
                    damage caused by fraudulent goods.
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img
                    src="/icons/real-world-asset.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Real World Assets Tokenisation</h4>
                  <p>
                    Efficient way to digitize tangible assets such as real
                    estate, art, commodities represented as digital tokens,
                    enabling fractional ownership, increased liquidity, and
                    streamlined transactions. Ensures the integrity and
                    authenticity of asset ownership, mitigating fraud and
                    enhancing trust among stakeholders.
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div> */}
            <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img
                    src="/icons/goverment-pubic-sector.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Healthcare</h4>
                  <p>
                    Doras facilitates secure and interoperable electronic health
                    records (EHRs), patient data management, and drug
                    traceability in the healthcare sector. With blockchain
                    technology, Doras ensures the integrity, privacy, and
                    accessibility of healthcare data while enabling secure data
                    sharing among stakeholders.
                  </p>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
            <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img
                    src="/icons/GP-Sector.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Government & Public Sector</h4>
                  <p>
                    Ensures data integrity and prevents tampering, making it
                    ideal for maintaining public records, voting systems, and
                    identity management platforms. Streamline bureaucratic
                    processes, reduce fraud, and enhance citizen trust by
                    providing immutable and auditable records of transactions.  
                    <br />
                    <br />
                    <br />
                    <br />
                    
                  </p>
                </div>
              </div>
            </div>

            <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img
                    src="/icons/health-care.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Education</h4>
                  <p>
                    In the education sector, Doras offers solutions for secure
                    credential issuance and verification, academic records
                    management, and lifelong learning tracking. With blockchain
                    technology, Doras ensures the integrity and authenticity of
                    academic credentials while enabling seamless data sharing
                    and verification.
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img
                    src="/icons/media-entertainment.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Media & Entertainment</h4>
                  <p>
                    In the realm of Media & Entertainment, Doras provides
                    immutable records of ownership and transactions, minimizing
                    disputes and ensuring fair compensation for artists,
                    musicians, and content creators. Through decentralized
                    content distribution platforms, Doras enables artists to
                    forge direct connections with their audiences, fostering
                    greater autonomy and control over their creative works.
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img
                    src="/icons/media-entertainment.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Gaming</h4>
                  <p>
                    Decentralized gaming platforms where developers and gamers
                    interact directly, eliminating the need for intermediaries
                    and fostering a more equitable distribution of revenue.
                    Implementation of complex in-game mechanics such as provably
                    fair gaming and decentralized autonomous organization
                    empowering players with greater control over their gaming
                    experiences.
                  </p>
                </div>
              </div>
            </div>
            <div className="outer-box">
              <div className="rt_service_box">
                <div>
                  <img src="/icons/ESG.svg" alt="" className="icon-briefcase" />
                  <h4>Energy</h4>
                  <p>
                    Doras facilitates peer-to-peer energy trading, renewable
                    energy certificate tracking, and grid management in the
                    energy sector. By leveraging blockchain's decentralized
                    architecture, Doras enables transparent and efficient energy
                    transactions, empowering consumers and optimizing energy
                    supply chains.
                    <br />
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <Link className="gradient_btn" to="/service">
            <span>Book An Appointment</span>{" "}
          </Link>
        </div>
      </div>
    </section>
  );
};

import { useState } from "react";
import { Link } from "react-router-dom";
import OtpVerification from "./verifyOtp";
import { post } from "../../service/apiService";
import { validateEmail } from "../../utils/utils";
import { config } from "../../config";
import Header from "../../components/Header copy/NewHeader";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Header2 from "../../components/header/header";
import "./index.css";
import "../../index.css";
const { sendOtp, forgotPassword } = config.authEndPoints;
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleSendOtp = async () => {
    if (validateEmail(email)) {
      try {
        const response = await post(
          sendOtp,
          {
            email: email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.success) {
          toast.success("Otp Send Successfully");
          setStep(2);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        console.log(error, "error");
      }
    }
  };
  const handleChangePassword = async () => {
    if (password?.newPassword === password?.confirmPassword) {
      const response = await post(forgotPassword, password, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (response?.success) {
        toast.success("Password Change Successfully");
        localStorage.setItem("isLogin", true);
        navigate("/service");
      } else {
        toast.error(response?.message);
      }
    }
  };
  return (
    <div>
      <Header2 />
      <div id="__next">
        {step === 1 && (
          <div id="rt_contact">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-6 col-md-offset-2"
                  style={{ margin: "0 auto" }}
                >
                  <div className="contact_form">
                    <div
                      class="mt_heading_white"
                      style={{ marginBottom: "20px" }}
                    >
                      <h2 class="section_heading text-center">
                        <span
                          class="heading_text"
                          style={{ textTransform: "capitalize" }}
                        >
                          Forgot Password
                        </span>
                      </h2>
                    </div>
                    <div id="contact_form">
                      <div className="row">
                        <div className="col-sm-12">
                          <label>Email:</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            placeholder="Enter your email id"
                            required={true}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-12">
                          <button
                            className="rt_btn_color"
                            id="submit-btn"
                            style={{ width: "100%", textAlign: "center" }}
                            onClick={handleSendOtp}
                          >
                            Submit
                            <span className="mt_load">
                              <span></span>
                            </span>
                          </button>
                          <div id="msg"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <OtpVerification email={email} changeState={() => setStep(3)} />
        )}
        {step === 3 && (
          <div id="__next">
            <div id="rt_contact">
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-6 col-md-offset-2"
                    style={{ margin: "0 auto" }}
                  >
                    <div className="contact_form">
                      <div
                        class="mt_heading_white"
                        style={{ marginBottom: "20px" }}
                      >
                        <h2 class="section_heading text-center">
                          <span class="heading_text">Forgot Password</span>
                        </h2>
                      </div>
                      <div id="contact_form">
                        <div className="row">
                          <div className="col-sm-12">
                            <label>New Password</label>
                            <div style={{ position: "relative" }}>
                              <input
                                type={passwordShow ? "text" : "password"}
                                name="newPassword"
                                id="newPassword"
                                placeholder="Enter new password"
                                required={true}
                                minLength={8}
                                maxLength={15}
                                onChange={(e) =>
                                  setPassword((prevState) => ({
                                    ...prevState,
                                    newPassword: e.target.value,
                                  }))
                                }
                              />
                              <img
                                src={
                                  passwordShow
                                    ? "/icons/showPassword.svg"
                                    : "/icons/password-hide.svg"
                                }
                                className="password-icon"
                                onClick={() => setPasswordShow(!passwordShow)}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <label>Confirm Password</label>
                            <div style={{ position: "relative" }}>
                              <input
                                type={confirmPassword ? "text" : "password"}
                                name="confirmPassword"
                                id="confirmPassword"
                                placeholder="Please enter confirm password"
                                required={true}
                                minLength={8}
                                maxLength={15}
                                onChange={(e) =>
                                  setPassword((prevState) => ({
                                    ...prevState,
                                    confirmPassword: e.target.value,
                                  }))
                                }
                              />
                              <img
                                src={
                                  confirmPassword
                                    ? "/icons/showPassword.svg"
                                    : "/icons/password-hide.svg"
                                }
                                className="password-icon"
                                onClick={() =>
                                  setConfirmPassword(!confirmPassword)
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button
                              className="rt_btn_color"
                              id="submit-btn"
                              style={{ width: "100%", textAlign: "center" }}
                              onClick={handleChangePassword}
                            >
                              Submit
                              <span className="mt_load">
                                <span></span>
                              </span>
                            </button>
                            <div id="msg"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;

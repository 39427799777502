import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { post } from "../../service/apiService";
import { config } from "../../config";
import OtpVerification from "./verifyOtp";
import Header2 from "../../components/header/header";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import "./index.css";
import "../../index.css";
const { register, googleSignup } = config.authEndPoints;
const Register = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setErrors] = useState({});
  const [step, setStep] = useState(1);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    full_name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must not exceed 50 characters")
      .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed"),
    email: Yup.string().email("Invalid email"),
    mobile: Yup.string().matches(/^[0-9]{10}$/, "Invalid phone number"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string(),
  });

  const handleRegister = async (e) => {
    const { name, value } = e.target;
    try {
      await Yup.reach(validationSchema, name).validate(value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
    }
    setFormData((prebvState) => ({ ...prebvState, [name]: value }));
  };
  const handleFormSubmit = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const response = await post(register, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.success) {
        toast.success("Otp Send Successfully");
        setStep(2);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error);
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  
  const handleGoogleLogin = async (data) => {
    const { clientId, credential } = data;
    console.log(data, "Data Hain ???");
    try {
      const response = await post(
        googleSignup,
        {
          credential: credential,
          clientId: clientId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { message, responseData, success } = response;
      if (success) {
        toast.success("Login Successfully");
        localStorage.setItem("token", responseData?.token);
        localStorage.setItem("isLogin", true);
        navigate("/service");
      } else {
        toast.error(message);
      }
    } catch (error) {}
  };
  return (
    <div id="__next">
      <Header2 />
      {step === 1 ? (
        <>
          <div id="rt_contact">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-6 col-md-offset-2"
                  style={{ margin: "0 auto" }}
                >
                  <div className="contact_form">
                    <div
                      className="mt_heading_white"
                      style={{ marginBottom: "20px" }}
                    >
                      <h2 className="section_heading text-center">
                        <span className="heading_text">Sign Up</span>
                      </h2>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-sm-12">
                          <label>Full Name:</label>
                          <input
                            type="text"
                            name="full_name"
                            id="fullName"
                            placeholder="Enter your name"
                            required={true}
                            value={formData.full_name}
                            onChange={handleRegister}
                          />
                          {error?.full_name && (
                            <span style={{ color: "red" }}>
                              {error?.full_name}
                            </span>
                          )}
                        </div>
                        <div className="col-sm-12">
                          <label>Email:</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your email id"
                            required={true}
                            value={formData.email}
                            onChange={handleRegister}
                          />
                          {error?.email && (
                            <span style={{ color: "red" }}>{error?.email}</span>
                          )}
                        </div>
                        <div className="col-sm-12">
                          <label>Phone Number:</label>
                          <input
                            type="text"
                            name="mobile"
                            id="mobile"
                            placeholder="Enter your mobile number"
                            required={true}
                            value={formData.mobile}
                            // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            onChange={handleRegister}
                          />
                          {error?.mobile && (
                            <span style={{ color: "red" }}>
                              {error?.mobile}
                            </span>
                          )}
                        </div>

                        <div className="col-sm-12">
                          <label>Password:</label>
                          <div>
                            <div style={{ position: "relative" }}>
                              <input
                                type={passwordShow ? "text" : "password"}
                                name="password"
                                id="password"
                                placeholder="Enter your password "
                                required={true}
                                onChange={handleRegister}
                                value={formData.password}
                              />
                              <img
                                src={
                                  passwordShow
                                    ? "/icons/showPassword.svg"
                                    : "/icons/password-hide.svg"
                                }
                                className="password-icon"
                                onClick={() => setPasswordShow(!passwordShow)}
                                alt=""
                              />
                            </div>
                            {error?.password && (
                              <span style={{ color: "red" }}>
                                {error?.password}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <label>Confirm Password:</label>
                          <div style={{ position: "relative" }}>
                            <input
                              type={confirmPassword ? "text" : "password"}
                              name="confirmPassword"
                              id="confirmPassword"
                              placeholder="Enter your confirm password"
                              required={true}
                              onChange={handleRegister}
                              value={formData.confirmPassword}
                            />
                            <img
                              src={
                                confirmPassword
                                  ? "/icons/showPassword.svg"
                                  : "/icons/password-hide.svg"
                              }
                              className="password-icon"
                              onClick={() =>
                                setConfirmPassword(!confirmPassword)
                              }
                              alt=""
                            />
                            {error?.confirmPassword && (
                              <span style={{ color: "red" }}>
                                {error?.confirmPassword}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <button
                            className="rt_btn_color"
                            id="submit-btn"
                            style={{ width: "100%", textAlign: "center" }}
                            onClick={handleFormSubmit}
                          >
                            Sign Up
                            <span className="mt_load">
                              <span></span>
                            </span>
                          </button>
                          <div id="msg"></div>
                        </div>
                        <div
                          className="col-sm-12"
                          style={{ position: "relative" }}
                        >
                          <hr style={{ color: "#000" }} />
                          <span className="or-text-2">Or</span>
                        </div>
                        <div
                      style={{
                        margin: "10px 0px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          handleGoogleLogin(credentialResponse);
                        }}
                        text={"signup_with"}
                        shape={"pill"}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                        className="google-login-button"
                        style={{ border: "none" }}
                      />
                    </div>
                        <Link
                          to={"/login"}
                          style={{
                            textDecoration: "none",
                            display: "block",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <p
                            className="text-center"
                            style={{ color: "#000", marginTop: "10px" }}
                          >
                            Login &nbsp;
                            <span style={{ color: "#5334ff" }}>Doras</span>
                            &nbsp; Account
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <OtpVerification email={formData?.email} type={"register"} />
      )}
    </div>
  );
};

export default Register;

import Header from "../../components/Header copy/NewHeader";
import { Footer } from "../../components/footer/footer";
import { Link } from "react-router-dom";
import "../../index.css";
import "./index.css";
export const PlugAndPlay = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Header click={scrollToSection}/>
      <div
        style={{ backgroundImage: "url(/images/sectiontop.svg)" }}
        className="topImg"
      ></div>
      <div className="container parent">
        <div className="row">
          <div className="Hero-Play-Pug-Section col-sm-6">
            <div>
              <img src="/images/plu&play.svg" alt="" />
            </div>
            <h3>Plug & Play Doras Platform</h3>
          </div>
        </div>
        <div className="Hero-Btm-Card-Container row">
          <div className="col-sm-4 play-card-box text-center">
            <img src="/images/baas.svg" alt="" />
            <h3 className="mb-10">Blockchain As A Service</h3>
            <div>
              <Link className="gradient_btn" to="/blockchain-development">
                <span>Plug & Play Demo</span>{" "}
              </Link>
            </div>
          </div>
          <div className="col-sm-4 play-card-box text-center">
            <img src="/images/naas.svg" alt="" />
            <h3 className="text-center">Node As A Service</h3>
            <div>
              <Link className="gradient_btn" to="/node-as-service">
                <span>Submit Requirements</span>{" "}
              </Link>
            </div>
          </div>
          <div className="col-sm-4 play-card-box text-center">
            <img src="/images/raas.svg" alt="" />
            <h3>Rollup As A Service</h3>
            <div>
              <Link className="gradient_btn" to="/rollsup">
                <span>Submit Requirements</span>{" "}
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="btm-play-card">
            <img src="/images/plugDemo.svg" alt="" />
            <div>
              <Link className="gradient_btn" to="/network-details">
                <span>
                  Complete your profile <br />
                  Execute your Geth Client
                </span>{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

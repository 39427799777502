import { useNavigate } from "react-router-dom";
import "../../index.css";
export const About = () => {
  const navigate = useNavigate()
  return (
    <div id="rt_screenshots" style={{cursor: "pointer"}} onClick={() => navigate('/about')}>
      <div className="container">
        <div>
          <div className="mt_heading_white" style={{ marginBottom: "0px" }}>
            <h2 className="section_heading text-center">
              <span className="faded_text">About Us</span>
              <span className="heading_text">About Us</span>
            </h2>
            <p className="heading_txt text-center">
              "DORAS" is a Blockchain Service Provider which acts as a gateway
              to access opportunities and solutions for leveraging blockchain
              technology.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
